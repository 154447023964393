<template>
	<div ref="scrreeningUnAdd" class="dialog-container">
		<div class="dialog" style="max-height: 700px; max-width: 1200px;transform: translate(0, -360px);">
			<form @submit.stop="doValidation()">
				<div class="top">
					<p class="txt-crud-title">
						Unschedule Update
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
					<colgroup>
						<col width="11%" />
						<col width="39%" />
						<col width="11%" />
						<col width="39%" />
					</colgroup>
					<tbody>
						<!-- <tr>
							<th>
								After Visit.
							</th>
							<td colspan="3">
								<select
									class="select-crud"
									name="originUnschedule"
									v-validate="'required'"
									data-vv-as="origin Unschedule"		
									@change="changeVisit($event)"
									v-model="this.item.afterVisitNo"
									style="max-width: 100%;"
								>
									<option value="" selected disabled>--- select ---</option>
									<template v-for="(visitListItem, index) in visitList">
										<option v-if="visitListItem.useYn == 'Y'" :key="index" :value="visitListItem.visitNo">
											{{ visitListItem.visitName }}
										</option>
									</template>
								</select>
								<p class="txt-validation" v-if="errors.has('originUnschedule')">
									* {{ errors.first("originUnschedule") }}
								</p>
							</td>
						</tr> -->
						<tr>
							<th>Visit Name.</th>
							<td colspan="3">
								<input
									type="text"
									name="visitName"
									class="input-crud"
									:class="(errors.has('visitName') || isDuplicate ? 'input-crud-error' : '')"
									:placeholder="(errors.has('visitName') ? '* ' + errors.first('visitName') : 'Please enter the Visit Name.')"
									v-model="item.visitName"
									v-validate="'required'"
									data-vv-as="Visit Name"
									@input="item.visitName = $event.target.value"
								/>
								<p v-if="isDuplicate" class="duplicate-balloon" style="position: inherit; padding-top: 7px; font-size: 9pt;">
								* Visit Name is duplicated.
								</p>
							</td>
						</tr>
						<!-- <tr>
							<th>Sort Order.</th>
							<td>
								<input
									type="number"
									min="1"
									max="100"
									v-model="visitSortOrderVO"
									class="input-crud"
									:class="(errors.has('visitSortOrder') ? 'input-crud-error' : '')"
									name="visitSortOrder"
									v-validate="'required'"
									data-vv-as="Sort Order"
									:placeholder="(errors.has('visitSortOrder') ? '* ' + errors.first('visitSortOrder') : 'Please enter the Sort Order.')"
								/>
							</td>
							<th>
								Site
							</th>
							<td>
								<select
									class="select-crud"
									:class="(errors.has('siteCode') ? 'input-crud-error' : '')"
									name="siteCode"
									v-model="item.siteCode"
									v-validate="'required'"
									data-vv-as="Site"
									style="width: 100%;"
									disabled
								>
									<option value="" selected disabled>--- select ---</option>
									<option v-for="(siteListItem, index) in siteList" :key="index" :value="siteListItem.siteCode">
										{{ siteListItem.siteName }}
									</option>
								</select>
							</td>
						</tr> -->
						<tr>
							<th>Register.</th>
							<td>{{ userInfo.userName }}({{ userInfo.userId }})</td>
						</tr>
						<tr>
							<th>Register Date.</th>
							<td>
								{{ item.firstRegistDate }}
							</td>
						</tr>
						
						<tr>
							<th>Use Status.</th>
							<td>
								<label class="radio-crud">
									<input
										type="radio"
										name="useYn"
										value="Y"
										v-validate="'required'"
										v-model="item.useYn"
										data-vv-as="Use Status"
									/><div class="radio" />
									Yes
								</label>
								<label class="radio-crud">
									<input
										type="radio"
										name="useYn"
										value="N"
										v-validate="'required'"
										v-model="item.useYn"
										data-vv-as="Use Status"
									/><div class="radio" />
									No
								</label>
							</td>
						</tr>
						<!-- <tr>
							<th>UnSchedule Status.</th>
							<td>
								<label class="radio-crud">
									<input
										type="radio"
										name="unscheduleYn"
										value="Y"
										v-model="item.unscheduleYn"
										v-validate="'required'"
										data-vv-as="UnSchedule Status"
										disabled
									/><div class="radio" />
									Yes
								</label>
								<label class="radio-crud">
									<input
										type="radio"
										name="unscheduleYn"
										value="N"
										v-model="item.unscheduleYn"
										v-validate="'required'"
										data-vv-as="UnSchedule Status"
										disabled
									/><div class="radio" />
									No
								</label>
							</td>
						</tr> -->
						<tr>
							<th>Page Name.</th>
							<td>
								<div v-for="visitPageItem in visitPageList" :key="visitPageItem.pageNo">
									<label class="checkbox-crud">
										<input
											type="checkbox"
											name='selectVisit'
											:value="visitPageItem.pageNo"
											v-model="visitPageSaveList"
											:disabled="screeningNoList.indexOf(visitPageItem.pageNo) > -1"
											v-validate="'required'"
											data-vv-as="Select PAGE NAME"
										/>
										<span class="checkbox"></span>
										{{ visitPageItem.pageName }}
									</label>
								</div>
								<p class="txt-validation" v-if="errors.has('selectVisit')">
									* {{ errors.first("selectVisit") }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
						Update
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="selectScreening()">
						Delete
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import EventBus from "@/script/EventBus.js";

export default {
    name: "ScreeningUnAdd",
    created () {
		this.logger.debug(this, "created()");
		this.item.firstRegistDate = moment().format("YYYY-MM-DD");
		this.item.useYn = this.$attrs.unUpdate.useYn;
		this.item.unscheduleYn = "Y";
		this.item.projectNo = this.$route.params.projectNo;
		this.item.screeningNo = this.$attrs.unUpdate.screeningNo;
		this.item.afterVisitNo = this.$attrs.unUpdate.afterVisitNo;
		this.item.visitName = this.$attrs.unUpdate.visitName;
		this.visitSortOrderVO = (this.$attrs.unUpdate.visitSortOrder).split('.').reverse()[0];
		this.item.siteCode = this.siteCode;
		this.selectedPageNo = this.$attrs.unUpdate.visitPageList;

		this.selectVisitList();
		this.selectSiteList();
		this.selectVisitPageList();		
    },
    data() {
        return {
			items: {},
			visitList: [],
			isDuplicate: false,
			item: {},
			visitPageList: [],
			selectedPageNo: [],
			visitPageItem: [],
			visitSortOrderVO: [],
			siteList: [],
			param: [],
			visitPageSaveList: [],
			screeningNoList: [],
			pageNoDeleteList: [],
			pageNoList: [],
		};
    },
	
	props: {
		siteCode: {
			type: String,
			required: true
		}
	},
    methods: {
		changeVisit(event)
		{
			console.log("changeVisit()");

			this.item.afterVisitNo   = event.target.value;
		},
		selectVisitList() {
			this.logger.debug(this, "selectVisitList()");

			this.items.projectNo = this.$route.params.projectNo;
			this.items.siteCode = this.siteCode;

			axios
				.post(this.config.contextRoot + "/screening/selectVisitList.do", this.items)
				.then((response) => {
					console.log(response);
					this.visitList = response.data.visitList;				
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectSiteList() {
			this.logger.debug(this, "selectSiteList()");
			axios
				.post(this.config.contextRoot + "/site/selectList.do", {})
				.then((response) => {
					console.log(response);
					this.siteList = response.data.siteList;					
					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectVisitPageList() {
			this.logger.debug(this, "selectVisigPageListEdit()");

			axios
				.post(this.config.contextRoot + "/visit/selectVisigPageListEdit.do", {projectNo: this.$route.params.projectNo, visitNo: this.$attrs.unUpdate.visitNo, unscheduleYn: "Y"})
				.then((response) => {
					console.log(response);
					this.visitPageList = response.data.visitPageList;

					for(let index = 0; index < this.visitPageList.length; index++)
					{
						if(this.visitPageList[index].selectedPageNo == 1)
						{
							this.visitPageSaveList.push(this.visitPageList[index].visitPageNo);
						}
						if(this.visitPageList[index].screeningCheck == 1)
						{
							this.screeningNoList.push(this.visitPageList[index].pageNo);
						}
						if(this.visitPageList[index].notScreeningCheck == 1)
						{
							this.pageNoDeleteList.push(this.visitPageList[index].pageNo);
						}
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		setDate(){
			
		},
        doValidation() {
			this.logger.debug(this, "doValidation()");

			if(this.isDuplicate == true)
			{
				return false;
			}

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						this.$confirm("Do you want to register the current Un Update?", "Question", "question").then(() => {
							EventBus.$emit("project-loading", true);
							this.doUpdate();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
        },
        doUpdate() {
            this.logger.debug(this, "doAdd()");

			for(let index = 0; index < this.visitPageSaveList.length; index++)
			{
				if(this.visitPageSaveList[index].indexOf(this.screeningNoList) == -1)
				{
					this.pageNoList.push(this.visitPageSaveList[index]);
				}
			}

			this.item.projectNo = this.$route.params.projectNo;
			this.item.siteCode  = this.siteCode;
			this.item.visitSortOrder = this.visitSortOrderVO;
			this.item.pageNoDeleteList = this.pageNoDeleteList;
			this.item.pageNoList = this.pageNoList;
			this.item.visitPageList = this.visitPageSaveList;
			this.item.screeningNoList = this.screeningNoList;
			this.$set(this.item, "visitNo", this.$attrs.unUpdate.visitNo);

			axios
				.post(this.config.contextRoot + "/screening/updateUnVisit.do", this.item)
				.then((response) => {
					
					if (response.status == 200)
					{
						console.log(response);
						this.$alert("It's been updateed.", "Info", "info").then(() => {
							location.reload();
						});
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
        },		
        doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the Un Update registration?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.scrreeningUnAdd.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.scrreeningUnAdd.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isUnUpdate");
			}.bind(this), 410);
		},
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");
			this.param = this.item;
			this.$set(this.param, "visitNo", this.$attrs.unUpdate.visitNo);
			axios
				.post(this.config.contextRoot + "/visit/duplicateCheck.do", this.param)
				.then((response) => {
					console.log(response);
					if(response.data.visitExistCount == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
		selectScreening() {
			this.logger.debug(this, "selectScreening()");

			axios
				.post(this.config.contextRoot + "/visit/selectScreening.do", {visitNo: this.$attrs.unUpdate.visitNo})
				.then((response) => {
					console.log(response);
					this.screeningList = response.data.screeningList;

					if(this.screeningList.length > 0)
					{
						this.$alert("I can't delete the data because there's a screening.", "Warn", "warning");
						return;
					}
					else if(this.screeningList.length == 0)
					{
						this.doDelete();
					}

				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doDelete() {
			this.logger.debug(this, "doDelete(visit)");

			let msg = "Are you sure you want to delete the visit?";
			this.$confirm(msg, "Question", "question").then(() => {
				EventBus.$emit("project-loading", true);

				var param = {projectNo: this.$route.params.projectNo, visitNo: this.$attrs.unUpdate.visitNo};
				axios
					.post(this.config.contextRoot + "/visit/delete.do", param)
					.then((response) => {
						console.log(response);
						location.reload();
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			});
		},
    },
}
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
</style>