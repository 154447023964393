<template>
	<div>
		<p class="txt-list-title">
			Data Dictionary List
		<button
			v-if="workAccessCheck('EXCEL DOWNLOAD')"
			type="button"
			class="btn-grey"
			title="Excel Download"
			style="float: right;"
			@click="doExcelDown()"
		>
			Excel
		</button>
		</p>
		<div class="dictionary-left">
			<!-- 필드 검색 -->
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the field or group name."
				style="margin: 0;"
				@keyup.enter="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="typing-balloon">
				<p>Please press the Enter key. </p>
			</div>
			<!-- 필드 리스트 wrap -->
			<div class="survey-category">
				<p class="title">CRF Configuration</p>
				<div class="prodic-category-list scroll no-drag">
					<div
						v-for="(pageItem, gindex) in pageDicList"
						:key="gindex"
						class="field-group page-question"
						:class="(pageCloseList.indexOf(pageItem.pageNo) >= 0 ? 'group-off' : '')"
					>
						<button
							type="button"
							class="group-name page-type"
							:class="(isClicked.type == 'page' && isClicked.id == pageItem.pageNo ? 'dic-check' : '')"
							:title="pageItem.pageName"
							@click="infoData(pageItem.pageNo, 'page', '', pageItem.pageName)"
						>
							<div class="groupIcon">
								Pa
							</div>
							<div class="proc-dic-list">
								{{ pageItem.pageName}} [Page]
							</div>
							<div
								class="pageDropdown"
								@click.stop="foldPage(pageItem.pageNo)"
							>
								&gt;
							</div>
						</button>
						<div
							v-for="(procedureItem, pIndex) in pageItem.procedureList"
							:key="pIndex"
							class="field-group proc-question"
							:class="(procedureCloseList.indexOf(procedureItem.procNo) >= 0 ? 'group-off' : '')"
						>
							<button
								type="button"
								class="group-name proc-type"
								:class="(isClicked.type == 'procedure' && isClicked.id == procedureItem.procNo ? 'dic-check' : '')"
								:title="procedureItem.procName"
								@click="infoData(procedureItem.procNo, 'procedure', '', (pageItem.pageName + ' > ' + procedureItem.procName))"
							>
								<div class="procIcon">
									Pr
								</div>
								<div class="proc-dic-list">
									{{ procedureItem.procName }} [Procedure]
								</div>
								<div
									class="procDropdown"
									@click="foldProc(procedureItem.procNo)"
								>
									&gt;
								</div>
							</button>
							<div
								v-for="(questionItem, qIndex) in procedureItem.procQuestionList"
								:key="qIndex"
								class="field-group proc-field-question"
								:class="(fieldCloseList.indexOf(questionItem.questionNo) >= 0 ? 'group-off' : '')"
							>
								<button
									type="button"
									class="group-name proc-question-type"
									:class="(isClicked.type == 'field' && isClicked.id == questionItem.questionNo ? 'dic-check' : '')"
									:title="questionItem.questionFieldName"
									@click="infoData(questionItem.questionNo, 'field', '', (pageItem.pageName + ' > ' + procedureItem.procName + ' > ' + questionItem.questionFieldName), procedureItem.procNo)"
								>
									<div class="fieldIcon">
										F
									</div>
									<div class="proc-dic-list">
										{{ questionItem.questionFieldName }} [{{ questionItem.fieldList[0].fieldTypeName }}: {{ questionItem.fieldList[0].dataTypeName }}]
									</div>
									<div
										class="procFieldDropdown"
										@click.stop="foldField(questionItem.questionNo)"
									>
										&gt;
									</div>
								</button>
								<template v-if="questionItem.fieldList[0].fieldTypeCd == '03' || questionItem.fieldList[0].fieldTypeCd == '04' || questionItem.fieldList[0].fieldTypeCd == '05' || questionItem.fieldList[0].fieldTypeCd == '12'">
									<div
										v-for="(tag, index) in getLabelList(questionItem.fieldList[0])"
										v-bind:key="index"
										class="field-group proc-item-question"
										:class="(componentCloseList.indexOf(questionItem.fieldList[0].fieldNo) >= 0 ? 'group-off' : '')"
									>
										<button
											type="button"
											class="group-name proc-item-type"
											:class="(isClicked.type == 'item' && isClicked.id == questionItem.fieldList[0].fieldNo && isClicked.tag == tag ? 'dic-check' : '')"
											:title="tag"
											@click="infoData(questionItem.fieldList[0].fieldNo, 'item', tag, (pageItem.pageName + ' > ' + procedureItem.procName + ' > ' + questionItem.questionFieldName + ' > ' + tag))"
										>
											<div class="itemIcon">
												I
											</div>
											<div class="proc-dic-list" style="width: calc(100% - 65px);">
												{{ tag }}
											</div>
										</button>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="dictionary-right">
			<div
				v-if="
					pageRowData.selectType == 'PAGE' || procRowData.selectType == 'PROCEDURE' ||
					fieldRowData.selectType == 'FIELD' || itemRowData.selectType == 'ITEM'
				"
			>
				<div
					:class="
						(
							pageRowData.selectType == 'PAGE' ? 'rightPageIcon' :
							procRowData.selectType == 'PROCEDURE' ? 'rightProcIcon' :
							fieldRowData.selectType == 'FIELD' ? 'rightFieldIcon' :
							itemRowData.selectType == 'ITEM' ? 'rightItemIcon' : ''
						)
					"
				>
					{{
						(
							pageRowData.selectType == 'PAGE' ? 'Pa' :
							procRowData.selectType == 'PROCEDURE' ? 'Pr' :
							fieldRowData.selectType == 'FIELD' ? 'F' :
							itemRowData.selectType == 'ITEM' ? 'I' : ''
						)
					}}
				</div>
				<span class="titleSpan">
					{{
						(
							pageRowData.selectType == 'PAGE' ? pageRowData.pageLabel :
							procRowData.selectType == 'PROCEDURE' ? procRowData.procedureName :
							fieldRowData.selectType == 'FIELD' ? fieldRowData.questionFieldName :
							itemRowData.selectType == 'ITEM' && itemRowData.fieldTypeCd != '12' ? itemRowData.itemLabeleName : itemRowData.itemValueName
						)
					}}
				</span>
				<div class="rightVal">
					{{ isRightVal }}
				</div>
				<p class="title">
					Dictionary Info
				</p>
			</div>
			<div class="scroll-dictionary scroll">
				<table class="dic-crud-horizon">
					<colgroup>
						<col width="16%" />
						<col width="84%" />
					</colgroup>
					<tbody v-if="pageRowData.selectType == 'PAGE'">
						<tr>
							<th>Page Name</th>
							<td>
								{{ pageRowData.pageName }}
							</td>
						</tr>
						<tr>
							<th>Page Label</th>
							<td>
								{{ pageRowData.pageLabel }}
							</td>
						</tr>
						<tr>
							<th>Sort Order</th>
							<td>
								{{ pageRowData.pageSortOrder }}
							</td>
						</tr>
						<tr>
							<th>Register</th>
							<td>
								{{ pageRowData.firstRegistUserId }}
							</td>
						</tr>
						<tr>
							<th>Register Date</th>
							<td>
								{{ pageRowData.firstRegistDate }}
							</td>
						</tr>
					</tbody>
					<tbody v-if="procRowData.selectType == 'PROCEDURE'">
						<tr>
							<th>Procedure Name</th>
							<td>
								{{ procRowData.procedureName }}
							</td>
						</tr>
						<tr>
							<th>Sort Order</th>
							<td>
								{{ procRowData.procSortOrder }}
							</td>
						</tr>
						<tr>
							<th>Register</th>
							<td>
								{{ procRowData.firstRegistUserId }}
							</td>
						</tr>
						<tr>
							<th>Register Date</th>
							<td>
								{{ procRowData.firstRegistDate }}
							</td>
						</tr>
					</tbody>
					<tbody v-if="fieldRowData.selectType == 'FIELD'">
							<tr>
								<th>Field Name</th>
								<td>
									{{ fieldRowData.questionFieldName }}
								</td>
							</tr>
							<tr>
								<th>Field Label</th>
								<td>
									{{ fieldRowData.questionName }}
								</td>
							</tr>
							<tr>
								<th>Field Type</th>
								<td>
									{{ fieldRowData.fieldTypeName }}
								</td>
							</tr>
							<tr>
								<th>Data Type</th>
								<td>
									{{ fieldRowData.dataTypeName }}
								</td>
							</tr>
							<tr>
								<th>Data Size</th>
								<td>
									{{ (fieldRowData.dataSize == "" ? "-" : fieldRowData.dataSize) }}
								</td>
							</tr>
							<tr>
								<th>Decimal Digit</th>
								<td>
									{{ (fieldRowData.decimalDigit == "" ? "-" : fieldRowData.decimalDigit) }}
								</td>
							</tr>
							<tr>
								<th>Component Layout</th>
								<td>
									{{ fieldRowData.fieldLayoutName }}
								</td>
							</tr>
							<tr>
								<th>Chart Type</th>
								<td>
									{{ fieldRowData.fieldChartName }}
								</td>
							</tr>
							<tr>
								<th>Prefix Label</th>
								<td>
									{{ (fieldRowData.prefixName == "" ? "-" : fieldRowData.prefixName) }}
								</td>
							</tr>
							<tr>
								<th>Suffix Label</th>
								<td>
									{{ (fieldRowData.suffixName == "" ? "-" : fieldRowData.suffixName) }}
								</td>
							</tr>
							<tr>
								<th>Allow Required</th>
								<td>
									{{ (fieldRowData.isRequired == "" ? "-" : fieldRowData.isRequired) }}
								</td>
							</tr>
							<tr v-if="fieldRowData.fieldTypeName == 'Date'">
								<th>Allow UK Type</th>
								<td>
									{{ (fieldRowData.isAllowUk == "" ? "-" : fieldRowData.isAllowUk) }}
								</td>
							</tr>
							<tr>
								<th>Sort Order</th>
								<td>
									{{ fieldRowData.questionSortOrder }}
								</td>
							</tr>
							<tr>
								<th>Register</th>
								<td>
									{{ fieldRowData.firstRegistUserId }}
								</td>
							</tr>
							<tr>
								<th>Register Date</th>
								<td>
									{{ fieldRowData.firstRegistDate }}
								</td>
							</tr>
							<tr>
								<th>Variant Setting</th>
								<td>
									<div class="con-group-wrap">
										<!-- <div class="result-row">
											<span class="result-row-label">
												Select Type
											</span>
											<div class="result-row-form">
												<label class="radio-crud">
													<input
														type="radio"
														name="variantType"
														v-model="fieldRowData.variantType"
														value="VISIT"
														@click.stop="resetValue()"
													/><div class="radio" />
													Visit
												</label>
												<label class="radio-crud">
													<input
														type="radio"
														name="variantType"
														v-model="fieldRowData.variantType"
														value="PAGE"
														@click.stop="resetValue()"
													/><div class="radio" />
													Page
												</label>
											</div>
										</div> -->
											<div
												v-for="(variant, variantIndex) in fieldRowData.variantList"
												:key="variantIndex"
												class="result-row"
											>
												<span class="result-row-label">
													{{ variantIndex == 0 ? 'Exclude Visit' : '' }}
												</span>
												<div class="result-row-form result-row-list">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															@click="openPageList('variant_value_' + variantIndex, $event);"
															:class="errors.has('variant_value_' + variantIndex) ? 'input-crud-error' : ''"
															:style="!utils.isEmpty(variant.variantValue) ? {color: '#999'} : {}"
														>
															{{
																(
																	!utils.isEmpty(variant.variantValue) ?
																	variant.variantValue.valueText :
																	'Please select'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == ('variant_value_' + variantIndex)"
															:validVisitList="validVisitList"
															:fieldTypeList="[]"
															v-model="variant.variantValue"
															endDepth="VISIT"
														/>
														<input
															type="hidden"
															:name="'variant_value_'+ variantIndex"
															v-model="variant.variantValue"
															v-validate="'required'"
															data-vv-as="Visit"
														/>
													</div>
													<button
														v-if="variantIndex == 0 && workAccessCheck('VARIANT SETTING') && !(projectStateCd == '4' || projectStateCd == '3')"
														type="button"
														class="btn-add-row"
														title="Add Row"
														@click.stop="addvariant(fieldRowData.variantList)"
													></button>
													<button
														v-if="workAccessCheck('VARIANT SETTING') && !(projectStateCd == '4' || projectStateCd == '3')"
														type="button"
														class="btn-remove-row"
														title="Remove Row"
														@click.stop="removevariant(fieldRowData.variantList, variantIndex)"
													></button>
												</div>
											</div>
									</div>
								</td>
							</tr>
							<tr 
								style="border-bottom: none;"
								v-if="workAccessCheck('VARIANT SETTING') && !(projectStateCd == '4' || projectStateCd == '3')"
							>
								<td colspan="2">
									<button
										type="button"
										class="btn-save fright"
										title="Save"
										@click.stop="saveVariant(fieldRowData)"
									>
										Save
									</button>
								</td>
							</tr>
						</tbody>
					<tbody v-if="itemRowData.selectType == 'ITEM'">
						<tr>
							<th>Item Type</th>
							<td>
								{{ itemRowData.fieldTypeName.toUpperCase() }} {{ itemRowData.selectType }}
							</td>
						</tr>
						<tr>
							<th>Item Label</th>
							<td>
								{{ itemRowData.itemLabeleName }}
							</td>
						</tr>
						<tr>
							<th>Item Value</th>
							<td>
								{{ itemRowData.itemValueName }}
							</td>
						</tr>
						<tr>
							<th>Data Type</th>
							<td >
								{{ itemRowData.dataTypeName }}
							</td>
						</tr>
						<tr>
							<th>Register</th>
							<td>
								{{ itemRowData.firstRegistUserId }}
							</td>
						</tr>
						<tr>
							<th>Register Date</th>
							<td>
								{{ itemRowData.firstRegistDate }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import DropList from "@/components/project/validation/DropList.vue";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ProjectDictionaryList",
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "2",
			privilegeCategory: "DICTIONARY",
			privilegeName: "ACCESS",
			projectNo: this.$route.params.projectNo
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	components: {
		DropList,
	},
	created() {
		this.logger.debug(this, "created()");

		EventBus.$emit("project-loading", true);

		this.getProjectStateCd();
		this.selectFieldList();
		this.selectValidVisitList();
		this.getAccessList('DICTIONARY');

		EventBus.$on("blurDropList", () => {
			this.logger.debug(this, "[EventBus] blurDropList");
			this.openFieldIndex = "";
		});
	},
	data() {
		return {
			item: {},
			items: {},
			pageDicList: [],

			validVisitList: [],
			pageList: [],

			pageCloseList : [],
			fieldCloseList: [],
			procedureCloseList : [],
			componentCloseList: [],

			pageRowData : {},
			procRowData : {},
			fieldRowData: {},
			itemRowData: {},
			
			openFieldIndex: "",

			isRightVal: "",
			isClicked: {},
			isTyping: false,
			accessList: [],

			projectStateCd: ""
		};
	},
	methods: {
		selectFieldList() {
			this.logger.debug(this, "selectFieldList()");
			this.pageCloseList = [];
			this.procedureCloseList = [];
			this.fieldCloseList = [];
			this.componentCloseList = [];

			this.pageRowData = {};
			this.procRowData = {};
			this.fieldRowData = {};
			this.itemRowData = {};

			this.isClicked = {};
			this.item.projectNo = this.$route.params.projectNo;
			axios
				.post(this.config.contextRoot + "/dictionary/projectDictionaryList.do", this.item)
				.then((response) => {
					console.log(response);
					this.pageDicList = response.data;

				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		// 초기 조회 기능 시작 (visit-부터 쭉 test)
		selectValidVisitList() {
			this.logger.debug(this, "selectValidVisitList()");
			axios
				.post(this.config.contextRoot + "/valid/selectValidVisitList.do", this.item)
				.then((response) => {
					console.log(response);
					this.validVisitList = response.data.validVisitList;
					this.pageList = response.data.validVisitList;

					this.$nextTick(function () {
						setTimeout(function() {
							EventBus.$emit("project-loading", false);
						}.bind(this), 400);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		addvariant(variantList) {
			this.logger.debug(this, "addvariant(variantList)");
			let list = {};

			variantList.push(list);
		},
		removevariant(variantList, variantIndex) {
			this.logger.debug(this, "removevariant(variantList, variantIndex)");
			variantList.splice(variantIndex, 1);
		}, 
		saveVariant(fieldInfo) {
			this.logger.debug(this, "saveVariant()");

			this.$validator
			.validateAll()
			.then((result) => {
				this.logger.debug(this, "doValidationResult:" + result);
				if(result){
					axios
					.post(this.config.contextRoot + "/dictionary/insertVariant.do", fieldInfo)
					.then((response) => {
						console.log(response);
						this.$nextTick(function () {
							setTimeout(function() {
								this.$alert("Success.", "Success", "success");
								EventBus.$emit("project-loading", false);
							}.bind(this), 400);
						});
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
				}
				else{
					this.$alert("select visit", "Warning", "warning");
					return false;
				}
			})
			.catch(() => {
				this.logger.debug(this, "-->" + this.errors.all());
				alert(this.errors.all());
				return false;
			});

			
		},
		resetValue(){
			this.logger.debug(this, "resetValue()");
			this.fieldRowData.variantValue = {};
		},
		openPageList(index, event) {
			this.logger.debug(this, index);
			// this.$set(this, "openFieldIndex", "");
			this.$set(this, "openFieldIndex", index);
			this.$nextTick(() => {
				event.target.parentNode.children[1].children[0].focus();
			});
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			this.item.projectNo = this.$route.params.projectNo;
			this.item.searchText = event.target.value;
			
			// EventBus.$emit("project-loading", true);
			this.selectFieldList();
		},
		getLabelList(qFieldItem) {
			let returnLabelList = [];
			switch (qFieldItem.fieldTypeCd)
			{
				case '03':
					returnLabelList = qFieldItem.radioOptionsLabel.split(';');
					break;
				case '04':
					returnLabelList = qFieldItem.checkboxOptionsLabel.split(';');
					break;
				case '05':
					returnLabelList = qFieldItem.selectOptionsLabel.split(';');
					break;
				case '12':
					returnLabelList = qFieldItem.usedDefaultList.split(';');
					break;
				default:
					alert("error");
					break;
			}
			return returnLabelList;
		},
		foldPage(pageNo) {
			this.logger.debug(this, "foldPage(pageNo)");
			let index = this.pageCloseList.indexOf(pageNo);
			if(index >= 0)
			{
				this.pageCloseList.splice(index, 1);
			}
			else
			{
				this.pageCloseList.push(pageNo);
			}
		},
		foldProc(procedureNo) {
			this.logger.debug(this, "foldProc(procedureNo)");
			let index = this.procedureCloseList.indexOf(procedureNo);
			if(index >= 0)
			{
				this.procedureCloseList.splice(index, 1);
			}
			else
			{
				this.procedureCloseList.push(procedureNo);
			}
		},
		foldField(questionNo){
			this.logger.debug(this, "foldField(questionNo)");
			let index = this.fieldCloseList.indexOf(questionNo);
			if(index >= 0)
			{
				this.fieldCloseList.splice(index, 1);
			}
			else
			{
				this.fieldCloseList.push(questionNo);
			}
		},
		infoData(selectNo, type, itemData, rightNav, procNo) {
			this.logger.debug(this, "infoData(selectNo, type, itemData, rightNav)");
			this.isClicked = {};
			this.isClicked.id = selectNo;
			this.isClicked.type = type;
			this.isClicked.tag = itemData;
			this.isRightVal = rightNav;
			
			axios
				.post(this.config.contextRoot + "/dictionary/select.do", {selectNo: selectNo, type: type, itemData: itemData, projectNo: this.$route.params.projectNo, procNo: procNo})
				.then((response) => {
					console.log(response);
					this.pageRowData = {};
					this.procRowData = {};
					this.fieldRowData = {};
					this.itemRowData = {};

					if(type == 'page') {
						this.pageRowData = response.data.dictionaryVo;
					}
					else if(type == 'procedure') {
						this.procRowData = response.data.dictionaryVo;
					}
					else if(type == 'field') {
						this.fieldRowData = response.data.dictionaryVo;
						if(this.fieldRowData.variantList.length == 0){
							let value = {};
							this.fieldRowData.variantList.push(value);
						}
						else{
							this.fieldRowData.variantList.forEach((item) => {
								let value = {
									visitNo : item.visitNo,
									valueText : item.visitName
								};
								this.$set(item, "variantValue", value);
							});
						}
					}
					else {
						this.itemRowData = response.data.dictionaryVo;
					}
					
					this.$nextTick(function () {
						EventBus.$emit("dictionary-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
		doExcelDown() {
			this.logger.debug(this, "doExcelDown()");

			this.items.projectNo = this.$route.params.projectNo;

			axios
				.post(this.config.contextRoot + "/excel/downloadExcelDictionary.do", this.items, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					if(response.status == 200)
					{						
						console.log(response);
						console.log(response.headers);
						const url = window.URL.createObjectURL(
							new Blob([response.data], {
								type: response.headers["content-type"],
							})
						);						
						
						const link = document.createElement("a");
						link.href = url;
						let today = new Date();
						let year = today.getFullYear();
						let month = ('0' + (today.getMonth() +1)).slice(-2);
						let day = ('0' + (today.getDate())).slice(-2);

						today = year + "-" + month + "-" + day;

						link.setAttribute("download", "DataDictionary_"+today +".xlsx");
						
						document.body.appendChild(link);
						link.click();
						
						this.$alert("The file has been downloaded.", "Info", "info");
					}

				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		// setDropValue(obj) {

		// },
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		}
	}
};
</script>

<style>
.btn-grey {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 35px;
	margin: 0 0 10px 10px;
	padding: 0 25px;
	border-radius: 5px;
	border: 1px solid #dee2e6;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #999;
}
.result-row {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 0 0 10px;
}

.result-row-label {
	width: 15%;
	font-weight: 600;
	font-size: 11pt;
}

.result-row-form {
	width: 85%;
	font-size: 11pt;
}

.result-row-list {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.result-row-list > .drop-list-wrap {
	width: calc(100% - 85px);
}
.con-group-wrap {
	margin: 10px 0;
	padding: 20px;
	border-radius: 10px;
	background-color: #f5f5f5;
}

.con-group-title {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: 25px;
	margin: 0 0 10px;
	font-weight: 600;
	font-size: 11pt;
	color: #2bade0;
	border-bottom: 1px solid #2bade0;
    padding: 10px 0px;
}

.con-operator-title {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: 25px;
	margin: 0 0 10px;
	font-weight: 600;
	font-size: 11pt;
	color: #2bade0;
}

.btn-small-remove {
	width: 20px;
	height: 20px;
	border: 1px solid #999;
	border-radius: 3px;
	background-color: #fff;
}

.con-detail-wrap {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin: 15px 0 0;
}

.btn-add-row {
	width: 32px;
	height: 32px;
	border-radius: 5px;
	background-color: #2bade0;
}

.btn-remove-row {
	width: 32px;
	height: 32px;
	border-radius: 5px;
	background-color: #c44944;
}

.con-detail-wrap > .label > p {
	margin: 0 0 5px;
	font-weight: 600;
	font-size: 10pt;
}

.select-field-value {
	font-weight: 600;
	font-size: 10pt;
	color: #999;
	text-decoration: underline;
}

.select-field-value:hover {
	color: #666;
}

.selected-field-value {
	color: #2bade0;
}

.selected-field-value:hover {
	color: #2bade0;
}

.con-detail-wrap > .label:nth-child(1) {
	width: 35%;
}

.con-detail-wrap > .label:nth-child(2) {
	width: 10%;
}

.con-detail-wrap > .label:nth-child(3) {
	width: 35%;
}

.con-detail-wrap > .label:nth-child(4) {
	width: 10%;
}

.drop-list-wrap {
	position: relative;
	width:100%;
	height: 32px;
}

.drop-list {
	display: block;
	position: relative;
	width: 100%;
	height: 32px;
	line-height: 31px;
	padding: 0 30px 0 15px;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 10pt;
	color: #ccc;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	-moz-user-select: none;
	-webkit-user-select: none;
	cursor: unset;
}

.drop-list::after {
	content: ">";
	display: inline-block;
	position: absolute;
	top: 0;
	right: 10px;
	font-weight: 600;
	font-family: cursive;
	transform: rotate(90deg);
}
</style>import { push } from "core-js/core/array";
