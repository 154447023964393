<template>
	<div ref="SendQuery" class="dialog-container">
        <div class="dialog" style="max-height: 550px; transform: translate(0, -300px); max-width: 700px;">
			<div class="top">
				<p class="txt-crud-title">
					Test Query Create
				</p>
			</div>
			<div class="center">
				<p class="txt-form-title">Please select an item for which to create a query.</p>
				<select
					class="select-crud"
					v-model="testQueryResultNo"
					v-validate="'required'"
					name="queryResultNo"
				>
					<option value="" disabled hidden>
						Please select the field
					</option>
					<option
					v-for="(queryResult, index) in queryResolution.queryResultVoList"
					:key="index"
					:value="queryResult.testQueryResultNo"
					>
						[{{getQueryStatusName(queryResult.queryStatusCd)}}] {{queryResult.visitName}} > {{queryResult.pageName}} > {{queryResult.procLabel}} > {{queryResult.questionName}}
					</option>
				</select>
				<p class="txt-form-title" style="margin-top: 20px;">Select user.</p>
				<div class="user-check">
					<label
						:key="userindex"
						class="checkbox-crud"
					>
						<input
							type="checkbox"
							name='checkAll'
							@click="checkAll($event)"
						/>
						<span class="checkbox"></span>
						ALL
					</label>
				</div>
				<div class="user-check">
					<label
						v-for="(user, userindex) in userList"
						:key="userindex"
						class="checkbox-crud"
					>
						<input
							type="checkbox"
							name='selectVisit'
							v-validate="'required'"
							data-vv-as="Select user"
							v-model="selectUser"
							:value="user.userId"
						/>
						<span class="checkbox"></span>
						{{user.userName}}(<span style="font-weight: bold;">{{ user.roleName }}</span>)
					</label>
				</div>
				<p class="txt-form-title" style="margin-top: 20px;">Please enter your query.</p>
				<textarea name="sendMsg" type="text" class="textarea-crud" v-validate="'required'" v-model="sendMsg" maxlength="500"></textarea>
				<p class="dynamic-warn" style="margin-top: 10px;">*If you create an open query, the contents of the query are immediately opened to the researcher.</p>
			</div>
			<div class="bottom">
                <button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
					Create
				</button>
                <button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doClose()">
                    Close
                </button>
            </div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "SendQuery",
	created() {
        this.logger.debug(this, "created()");
		this.queryResolution = this.selectQueryResolution;
		this.firstRegistUserId = this.userInfo.userId;

		this.selectUserList();

    },
    props: {
		selectQueryResolution: {
			type: Object,
			required: true
		},
		queryStatusList: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
            item: {},
            queryResolution: {},
			testQueryResultNo: "",
			sendMsg: "",
			userList: {},
			selectUser: [],
		};
    },
    watch: {
	},
	methods: {
		selectUserList(){
			this.item = {
				projectNo: this.$route.params.projectNo,
				privilegeCategory: "TEST ENTRY",
				confirmYn: "Y",
			}
			axios
				.post(this.config.contextRoot + "/user/selectQueryUserList.do", this.item)
				.then((response) => {
					this.userList = response.data.userList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						// if(this.queryOpenChk()){
						// 	this.$alert("Already Open Query!", "Warning", "warning");
						// }
						// else{
							this.doInsert();
						// }
					}
					else{
						this.$alert("Please enter all fields!", "Warning", "warning");
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		queryOpenChk(){
			this.logger.debug(this, "queryOpenChk()");
			let bool = false;
			for(let i = 0; i < this.queryResolution.queryResultVoList.length; i++){
				if(this.queryResolution.queryResultVoList[i].testQueryResultNo == this.testQueryResultNo)
				{
					if(this.queryResolution.queryResultVoList[i].queryStatusCd == '1'){
						console.log(this.queryResolution.queryResultVoList[i].queryStatusCd);
						bool = true;
					}
				}
			}
			console.log(bool);
			return bool;
		},
		getQueryStatusName(code){
			let comCodeList = this.queryStatusList.map((item) => {
					return item.comCode;
				});
				let getIndex = comCodeList.indexOf(code);
				return this.queryStatusList[getIndex].comCodeName;
		},
		doInsert() {
			this.item = {
				testQueryResultNo: this.testQueryResultNo,
				sendMsg: this.sendMsg,
				firstRegistUserId: this.firstRegistUserId,
				lastModifyUserId: this.firstRegistUserId,
				userList: this.selectUser,
				testScreeningNo: this.queryResolution.queryResultVoList[0].testScreeningNo
			}
			axios
				.post(this.config.contextRoot + "/testQueryResolution/insert.do", this.item)
				.then((response) => {
					if (response.status == 200) {
						this.$alert("Query has been saved successfully.", "Info", "info").then(() => {
							this.doClose();
							location.reload();
						});
					} else {
						alert(response.status);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.SendQuery.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.SendQuery.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("view-close", "isSendQuery");
			}.bind(this), 410);
		},
		checkAll(event) {
			this.logger.debug(this, "checkAll(event)");
			if (event.target.checked) {
				this.selectUser = this.userList.map((item) => {
					return item.userId;
				});
			} else {
				this.selectUser = [];
			}
		},
	}
};
</script>
<style scoped>
.btn-cancel {
	margin-right: 10px;
}
.txt-form-title {
	height: 35px;
	line-height: 35px;
	margin: 0;
	font-weight: 600;
	font-size: 11pt;
	color: #999;
}
.dynamic-warn {
    font-size: 9pt;
    color: rgb(224, 91, 91);
	font-weight: 600;
}
.red {
	color: rgb(224, 91, 91);
}
.green {
	color: #9ecc5a;
}

.blue{
	color: #2bade0;
}
.user-check{
	font-weight: 400;
    font-size: 10pt;
    color: #999;
    text-align: left;
}
</style>