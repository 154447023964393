<template>
	<div class="no-drag" :class="(isProject || isSetting ? 'topbar-menu-container' : 'topbar-container')">
		<!-- Notice or Project Main Start -->
		<template v-if="isNotice || isProjectMain || isAttendanceMain">
			<span class="logo-wrap">{{ title }}</span>
			<input
				type="text"
				class="input-list-search"
				name="searchText"
				@keyup="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
				:placeholder="'Please enter the ' + menuName.toLowerCase() + ' title to search.'"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="topbar-balloon">
				<p>Please press the Enter key. </p>
			</div>
			<button type="button" class="btn-mypage1" title="My Page" @click="doMypage()"></button>
		</template>
		<!-- Notice or Project Main End -->
		<!-- In Project Start -->
		<template v-if="isProject">
			<div class="logo-project-wrap">
				<span class="project-name" :title="projectItem.projectStudyTitle">
					{{ "(V"+projectItem.projectVersion+")" + projectItem.projectStudyTitle}}
				</span>
				<button type="button" class="btn-help" @click="dropBalloon($event)" @blur="hideBalloon($event)">
					?
					<div v-if="isDrop" class="project-balloon">
						<p>{{ (projectItem.projectNote == "" ? "No Contents." : projectItem.projectNote) }}</p>
					</div>
				</button>
				<button type="button" class="btn-mypage1" title="My Page" @click="doMypage()"></button>
			</div>
			<div class="topbar-menu-wrap">
				<ul class="topbar-menu">
					<template v-if="projectItem.projectType == '1'">
						<li
							v-for="(menu, index) in projectMenu"
							:key="index"
							:class="(menu.accessCheck ? (openMenuIndex == index ? 'clicked' : '') : 'hidden-important')"
							@click="moveMenu(menu)"
						>
							{{ menu.menuName }}
						</li>
					</template>
					<template v-if="projectItem.projectType == '2'">
						<li
							v-for="(menu, index) in attendanceMenu"
							:key="index"
							:class="(openMenuIndex == index ? 'clicked' : '')"
							@click="moveMenu(menu)"
						>
							{{ menu.menuName }}
						</li>
					</template>
				</ul>
			</div>
		</template>
		<!-- In Project End -->
		<!-- Setting Start -->
		<template v-if="isSetting">
			<div class="logo-project-wrap">
				<span class="project-name">
					Setting
				</span>
				<button type="button" class="btn-mypage1" title="My Page" @click="doMypage()"></button>
			</div>
			<div class="topbar-menu-wrap">
				<ul class="topbar-menu">
					<li
						v-for="(menu, index) in settingMenu"
						:key="index"
						:class="(menu.accessCheck ? (openMenuIndex == index ? 'clicked' : '') : 'hidden-important')"
						@click="moveMenu(menu)"
					>
						{{ menu.menuName }}
					</li>
				</ul>
			</div>
		</template>
		<!-- Setting End -->
		<!-- ETC Start -->
		<template v-if="!isNotice && !isProject && !isProjectMain && !isSetting && !isAttendanceMain">
			<span class="logo-wrap">{{ title }}</span>
			<button type="button" class="btn-mypage1" title="My Page" @click="doMypage()"></button>
		</template>
		<!-- ETC End -->
		<MyPage v-if="isMypage" />
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import MyPage from "@/components/mypage/MyPage.vue"

export default {
	name: "Topbar",
	components: {
		MyPage
	},
	created() {
		this.logger.debug(this, "created()");
		this.doInit();

		// My Page 새 창 닫기
		EventBus.$on("mypage-cancel", () => {
			this.logger.debug(this, "[EventBus] mypage-cancel()");
			this.isMypage = false;
		});

		//menu accessCheck 설정
		this.settingMenu.forEach((obj) => {
			this.accessCheck(obj, "SETTING");
		});
		
		this.projectMenu.forEach((obj) => {
			this.accessCheck(obj, "PROJECT");
		});
	},
	props: {
		menuName: {
			type: String,
			required: true
		},
	},
	data() {
		return {
			item: {},
			title: "",
			searchText: "",
			searchTextVal:"",
			isMypage: false,
			isDrop: false,
			projectNo: "",
			projectItem: {},
			isTyping: false,
			projectMenu: [
				{
					menuName: "Page",
					menuUrl: "/page",
					accessCheck: false,
				},
				// {
				// 	menuName: "Procedure",
				// 	menuUrl: "/procedure"
				// },
				{
					menuName: "Dictionary",
					menuUrl: "/projectDictionary",
					accessCheck: false,
				},
				{
					menuName: "Visit",
					menuUrl: "/visit",
					accessCheck: false,
				},
				{
					menuName: "Schedule",
					menuUrl: "/schedule",
					accessCheck: false,
				},
				{
					menuName: "Validation",
					menuUrl: "/validation",
					accessCheck: false,
				},
				{
					menuName: "Calculation",
					menuUrl: "/calculation",
					accessCheck: false,
				},
				
				{
					menuName: "Entry",
					menuUrl: "/screening",
					accessCheck: false,
				},
				{
					menuName: "Test Entry",
					menuUrl: "/testScreening",
					accessCheck: false,
				},
				{
					menuName: "Query Resolution",
					menuUrl: "/queryResolution",
					accessCheck: false,
				},
				{
					menuName: "Test Query Resolution",
					menuUrl: "/testQueryResolution",
					accessCheck: false,
				},
				// {
				// 	menuName: "Freezing",
				// 	menuUrl: "/freezing"
				// },
				// {
				// 	menuName: "Monitoring",
				// 	menuUrl: "/monitoring"
				// },
				// {
				// 	menuName: "Site Data",
				// 	menuUrl: "/site_data"
				// // },
				{
					menuName: "Role",
					menuUrl: "/role",
					accessCheck: false,
				},
				{
					menuName: "User",
					menuUrl: "/user",
					accessCheck: false,
				},
				{
					menuName: "Site",
					menuUrl: "/site",
					accessCheck: false,
				},
				{
					menuName: "OCR",
					menuUrl: "/ocr",
					accessCheck: false,
				},
				// {
				// 	menuName: "Comment",
				// 	menuUrl: "/comment"
				// }
			],
			attendanceMenu: [
				{
					menuName: "Page",
					menuUrl: "/page"
				},
				// {
				// 	menuName: "Procedure",
				// 	menuUrl: "/procedure"
				// },
				{
					menuName: "Dictionary",
					menuUrl: "/projectDictionary"
				},
				{
					menuName: "Visit",
					menuUrl: "/visit"
				},
				{
					menuName: "Schedule",
					menuUrl: "/schedule"
				},
				{
					menuName: "Validation",
					menuUrl: "/validation"
				},
				{
					menuName: "Calculation",
					menuUrl: "/calculation"
				},
				{
					menuName: "Entry",
					menuUrl: "/screening"
				},
			],
			settingMenu: [
				{
					menuName: "Site",
					menuUrl: "/site",
					accessCheck: false,
				},
				{
					menuName: "Auth",
					menuUrl: "/auth",
					accessCheck: false,
				},
				{
					menuName: "User Auth",
					menuUrl: "/user_auth",
					accessCheck: false,
				},
				{
					menuName: "EMR Column Manage",
					menuUrl: "/emr_column_manage",
					accessCheck: false,
				},
				{
					menuName: "EMR Data Manage",
					menuUrl: "/emr_data_manage",
					accessCheck: false,
				}
			]
		};
	},
	computed: {
		isProjectMain: function() {
			return (this.projectNo == "" && this.menuName == "Project" ? true : false);
		},
		isAttendanceMain: function() {
			return (this.projectNo == "" && this.menuName == "Attendance" ? true : false);
		},
		isProject: function() {
			return (this.projectNo == "" ? false : true);
		},
		isNotice: function() {
			return (this.menuName == "Notice" ? true : false);
		},
		isSetting: function() {
			return (this.menuName == "Setting" ? true: false);
		},
		openMenuIndex: function() {
			// 메뉴 셋팅
			// projectMenu: ["Survey", "Event", "Data", "Inspection", "User", "Comment"]
			// settingMenu: ["Agency", "Auth", "User", "EMR Connect", "EMR Mapping"]
			let path = this.$route.path;
			if(this.isProject)
			{
				if (path.indexOf("/page") >= 0) return 0;
				// else if(path.indexOf("/procedure") >= 0) return 1;
				else if(path.indexOf("/projectDictionary") >= 0) return 1;
				else if(path.indexOf("/visit") >= 0) return 2;
				else if(path.indexOf("/schedule") >= 0) return 3;
				else if(path.indexOf("/validation") >= 0) return 4;
				else if(path.indexOf("/calculation") >= 0) return 5;
				else if(path.indexOf("/screening") >= 0) return 6;
				else if(path.indexOf("/testScreening") >= 0) return 7;
				else if(path.indexOf("/queryResolution") >= 0) return 8;
				else if(path.indexOf("/testQueryResolution") >= 0) return 9;
				// else if(path.indexOf("/freezing") >= 0) return 8;
				// else if(path.indexOf("/monitoring") >= 0) return 9;
				// else if(path.indexOf("/site_data") >= 0) return 10;
				else if(path.indexOf("/role") >= 0) return 10;
				else if(path.indexOf("/user") >= 0) return 11;
				else if(path.indexOf("/site") >= 0) return 12;
				else if(path.indexOf("/ocr") >= 0) return 13;
				// else if(path.indexOf("/comment") >= 0) return 13;
				else return -1;
			}
			else
			{
				if (path.indexOf("/site") >= 0) return 0;
				else if(path.indexOf("/auth") >= 0 || path.indexOf("/validation") >= 0) return 1;
				else if(path.indexOf("/user_auth") >= 0) return 2;
				else if(path.indexOf("/emr_column_manage") >= 0) return 3;
				else if(path.indexOf("/emr_data_manage") >= 0) return 4;
				else return -1;
			}
		}
	},
	watch: {
		searchText: function(val) {
			this.searchTextVal = val;
		},
		$route() {
			this.doInit();
		},
	},
	methods: {
		doInit() {
			this.logger.debug(this, "doInit()");
			// title 셋팅
			if(this.menuName == "Notice") this.title = "Notice";
			else this.title = this.menuName;
			// projectNo 셋팅
			if(this.menuName == "Project")
			{
				if(this.$route.params.projectNo === undefined || this.$route.params.projectNo == "") this.projectNo = "";
				else
				{
					this.projectNo = this.$route.params.projectNo;
					axios
						.post(this.config.contextRoot + "/project/select.do", {projectNo: this.projectNo})
						.then((response) => {
							console.log(response);
							this.projectItem = response.data.item;
						})
						.catch((error) => {
							this.exceptionHandler(this, error);
						});
				}
			}
			// isDrop 초기화
			this.isDrop = false;
		},
		doMypage() {
			this.logger.debug(this, "doMypage()");
			this.isMypage = true;
		},
		dropBalloon(event) {
			this.logger.debug(this, "dropBalloon(event)");
			this.isDrop = true;
			this.$nextTick(function () {
				event.target.children[0].style.top = (event.clientY - event.offsetY - 7) + "px";
				event.target.children[0].style.left = (event.clientX - event.offsetX + 25) + "px";
			});
		},
		hideBalloon(event) {
			this.logger.debug(this, "hideBalloon(event)");
			event.target.children[0].style.animation = "hideTopBalloon 0.3s ease-out both";
			setTimeout(function() {
				this.isDrop = false;
			}.bind(this), 310);
		},
		moveMenu(menu) {
			this.logger.debug(this, "movePage(url)");
			let url = "";

			if(this.isProject) url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + menu.menuUrl;
			else if(this.isSetting) url = this.config.contextRoot + "/setting" + menu.menuUrl;

			if (this.$route.path !== url)
			{
				if(this.isProject) EventBus.$emit("project-loading", true);
				else if(this.isSetting) EventBus.$emit("setting-loading", true);
				
				this.$router.push(url);
			}
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			this.searchTextVal = event.target.value;
			if (event.keyCode == 13)
			{
				if(this.menuName == "Notice") EventBus.$emit("notice-search", this.searchTextVal);
				else if(this.menuName == "Project") EventBus.$emit("project-search", this.searchTextVal);
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
		async accessCheck(menu, type){
			let item = {};
			// setting menu
			if(menu.menuName == "EMR Data Manage"){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "1",
					privilegeCategory: "SETTING",
					privilegeName: "EMR DATA MANAGE ACCESS"
				}
			}
			else if(menu.menuName == "EMR Column Manage"){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "1",
					privilegeCategory: "SETTING",
					privilegeName: "EMR COLUMN MANAGE ACCESS"
				}
			}
			else if(menu.menuName == 'Site' || menu.menuName == 'Auth' || menu.menuName == 'User Auth'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "1",
					privilegeCategory: "SETTING",
					privilegeName: "ACCESS"
				}
			}
			//project menu
			else if(menu.menuName == 'Page'){	
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "PAGE",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Dictionary'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "DICTIONARY",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Visit'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "VISIT",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Schedule'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "SCHEDULE",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Validation'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "VALIDATION",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Calculation'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "CALCULATION",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Entry'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "ENTRY",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Test Entry'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "TEST ENTRY",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Query Resolution'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "QUERY RESOLUTION",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Test Query Resolution'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "TEST QUERY RESOLUTION",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Role'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "ROLE",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'User'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "USER",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			else if(menu.menuName == 'Site'){
				item = {
					userId: sessionStorage.getItem("userId"),
					privilegeTypeCd: "2",
					privilegeCategory: "SITE",
					privilegeName: "ACCESS",
					projectNo: this.$route.params.projectNo
				}
			}
			let res = await this.$accessAuth(item);
			console.log(type);
			menu.accessCheck = res;
			// if(type = "SETTING"){
			// 	this.settingMenu.forEach((obj) =>{
			// 		if(obj.menuName == menu.menuName){
			// 			obj.accessCheck = res;
			// 		}
			// 	})
			// }
			// else if(type = "PROJECT"){
			// 	this.projectMenu.forEach((obj) =>{
			// 		if(obj.menuName == menu.menuName){
			// 			obj.accessCheck = res;
			// 		}
			// 	})
			// }
		}
	}
};
</script>

<style>
@import "../../assets/css/common/topbar.css";
</style>