<template>
	<div>
		<p class="txt-list-title">
			Data Dictionary List
		</p>
		<div class="dictionary-left">
			<!-- 필드 검색 -->
			<input
				type="text"
				class="input-list-search"
				placeholder="Please enter the field or group name."
				style="margin: 0;"
				@keyup.enter="keyupHandler"
				@focus="infoBalloon"
				@blur="infoBalloon"
			/>
			<div v-if="isTyping" ref="typingBalloon" class="typing-balloon">
				<p>Please press the Enter key.</p>
			</div>
			<!-- 필드 리스트 wrap -->
			<div class="survey-category">
				<p class="title">Dictionary List</p>
				<div class="dic-category-list scroll no-drag">
					<div
						v-for="(groupItem, gindex) in fieldList"
						:key="gindex"
						class="field-group"
						:class="(groupCloseList.indexOf(groupItem.questionGroupNo) >= 0 ? 'group-off' : '')"
					>
						<button
							type="button"
							class="group-name group-type"
							:class="(isClicked.type == 'group' && isClicked.id == groupItem.questionGroupNo ? 'dic-check' : '')"
							:title="groupItem.questionGroupName"
							@click="infoData(groupItem.questionGroupNo, 'group', '', groupItem.questionGroupName)"
						>
							<div class="groupIcon">
								G
							</div>
							<div class="dic-list">
								{{ groupItem.questionGroupName }}
							</div>
							<div
								class="groupDropdown"
								@click.stop="foldGroup(groupItem.questionGroupNo)"
							>
								&gt;
							</div>
						</button>
						<div
							v-for="(questionItem, qIndex) in groupItem.questionList"
							:key="qIndex"
							class="field-group field-question"
							:class="(fieldCloseList.indexOf(questionItem.questionNo) >= 0 ? 'group-off' : '')"
						>
							<button
								type="button"
								class="group-name field-type"
								:class="(isClicked.type == 'field' && isClicked.id == questionItem.questionNo ? 'dic-check' : '')"
								:title="questionItem.questionFieldName + '('+questionItem.questionName+')'"
								@click="infoData(questionItem.questionNo, 'field', '', (groupItem.questionGroupName + ' > ' + questionItem.questionFieldName))"
							>
								<div class="fieldIcon">
									F
								</div>
								<div class="dic-list">
									{{ questionItem.questionFieldName }} [{{ questionItem.fieldList[0].fieldTypeName }}: {{ questionItem.fieldList[0].dataTypeName }}]
									<!-- <br><span style="font-size: 10px;">{{ questionItem.questionName }}</span> -->
								</div>
								<div
									class="fieldDropdown"
									@click.stop="foldField(questionItem.questionNo)"
								>
									&gt;
								</div>
							</button>
							<template v-if="questionItem.fieldList[0].fieldTypeCd == '03' || questionItem.fieldList[0].fieldTypeCd == '04' || questionItem.fieldList[0].fieldTypeCd == '05' || questionItem.fieldList[0].fieldTypeCd == '12'">
								<div
									v-for="(tag, index) in getLabelList(questionItem.fieldList[0])"
									v-bind:key="index"
									class="field-group item-question"
									:class="(componentCloseList.indexOf(questionItem.fieldList[0].fieldNo) >= 0 ? 'group-off' : '')"
								>
									<button
										type="button"
										class="group-name item-type"
										:class="(isClicked.type == 'item' && isClicked.id == questionItem.fieldList[0].fieldNo && isClicked.tag == tag ? 'dic-check' : '')"
										:title="tag"
										@click="infoData(questionItem.fieldList[0].fieldNo, 'item', tag, (groupItem.questionGroupName +' > '+ questionItem.questionFieldName + ' > ' + tag))"
									>
										<div class="itemIcon">
											I
										</div>
										<div class="dic-list" style="width: calc(100% - 65px);">
											{{ tag }}
										</div>
									</button>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="dictionary-right">
			<form @submit.prevent="doValidation()" style="height: 100%;">
				<div
					v-if="
						groupRowData.selectType == 'GROUP' || fieldRowData.selectType == 'FIELD' ||itemRowData.selectType == 'ITEM'
					"
				>
					<div
						:class="
							(
								groupRowData.selectType == 'GROUP' ? 'rightGroupIcon' :
								fieldRowData.selectType == 'FIELD' ? 'rightFieldIcon' :
								itemRowData.selectType == 'ITEM' ? 'rightItemIcon' : ''
							)
						"
					>
						{{
							(
								groupRowData.selectType == 'GROUP' ? 'G' :
								fieldRowData.selectType == 'FIELD' ? 'F' :
								itemRowData.selectType == 'ITEM' ? 'I' : ''
							)
						}}
					</div>
					<span class="titleSpan">
						{{
							(
								groupRowData.selectType == 'GROUP' ? groupRowData.questionGroupName :
								fieldRowData.selectType == 'FIELD' ? fieldRowData.questionFieldName :
								itemRowData.selectType == 'ITEM' && itemRowData.fieldTypeCd != '12' ? itemRowData.itemLabeleName : itemRowData.itemValueName
							)
						}}
					</span>
					<div class="rightVal">
						{{ isRightVal }}
					</div>
					<p class="title">Dictionary Info</p>
				</div>
				<div class="scroll-dictionary scroll">
					<table class="dic-crud-horizon">
						<colgroup>
							<col width="16%" />
							<col width="84%" />
						</colgroup>
						<tbody v-if="groupRowData.selectType == 'GROUP'">
							<tr>
								<th>Group Name</th>
								<td>
									{{ groupRowData.questionGroupName }}
								</td>
							</tr>
							<tr>
								<th>Sort Order</th>
								<td>
									{{ groupRowData.questionGroupSortOrder }}
								</td>
							</tr>
							<tr>
								<th>Register</th>
								<td>
									{{ groupRowData.firstRegistUserId }}
								</td>
							</tr>
							<tr>
								<th>Register Date</th>
								<td>
									{{ groupRowData.firstRegistDate }}
								</td>
							</tr>
						</tbody>
						<tbody v-if="fieldRowData.selectType == 'FIELD'">
							<tr>
								<th>Field Name</th>
								<td>
									{{ fieldRowData.questionFieldName }}
								</td>
							</tr>
							<tr>
								<th>Field Label</th>
								<td>
									{{ fieldRowData.questionName }}
								</td>
							</tr>
							<tr>
								<th>Field Type</th>
								<td>
									{{ fieldRowData.fieldTypeName }}
								</td>
							</tr>
							<tr>
								<th>Data Type</th>
								<td>
									{{ fieldRowData.dataTypeName }}
								</td>
							</tr>
							<tr>
								<th>Data Size</th>
								<td>
									{{ (fieldRowData.dataSize == "" ? "-" : fieldRowData.dataSize) }}
								</td>
							</tr>
							<tr>
								<th>Decimal Digit</th>
								<td>
									{{ (fieldRowData.decimalDigit == "" ? "-" : fieldRowData.decimalDigit) }}
								</td>
							</tr>
							<tr>
								<th>Component Layout</th>
								<td>
									{{ fieldRowData.fieldLayoutName }}
								</td>
							</tr>
							<tr>
								<th>Chart Type</th>
								<td>
									{{ fieldRowData.fieldChartName }}
								</td>
							</tr>
							<tr>
								<th>Prefix Label</th>
								<td>
									{{ (fieldRowData.prefixName == "" ? "-" : fieldRowData.prefixName) }}
								</td>
							</tr>
							<tr>
								<th>Suffix Label</th>
								<td>
									{{ (fieldRowData.suffixName == "" ? "-" : fieldRowData.suffixName) }}
								</td>
							</tr>
							<tr>
								<th>Allow Required</th>
								<td>
									{{ (fieldRowData.isRequired == "" ? "-" : fieldRowData.isRequired) }}
								</td>
							</tr>
							<tr v-if="fieldRowData.fieldTypeName == 'Date'">
								<th>Allow UK Type</th>
								<td>
									{{ (fieldRowData.isAllowUk == "" ? "-" : fieldRowData.isAllowUk) }}
								</td>
							</tr>
							<tr>
								<th>Sort Order</th>
								<td>
									{{ fieldRowData.questionSortOrder }}
								</td>
							</tr>
							<tr>
								<th>Register</th>
								<td>
									{{ fieldRowData.firstRegistUserId }}
								</td>
							</tr>
							<tr>
								<th>Register Date</th>
								<td>
									{{ fieldRowData.firstRegistDate }}
								</td>
							</tr>
							<tr>
								<th>Converting Data</th>
								<td>
									<label
										v-for="(option, checkboxIndex) in convertColumnList"
										:key="checkboxIndex"
										class="checkbox-dic"
									>
										<input
											type="checkbox"
											:value="option.columnNo"
											v-model="selectedPageNo"
											:disabled="!workAccessCheck('EMR MAPPING')"
										/><span class="checkbox"></span>
										<p><b style="color:#666;">{{ option.columnEnglishName}}</b>{{" [" + option.columnKoreanName + "]"}}</p>
									</label>
								</td>
							</tr>
							<tr style="border-bottom: none;">
								<td colspan="2">
									<button type="button" class="btn-save fright" title="Save" @click.stop="doValidation('Field')" v-if="workAccessCheck('EMR MAPPING')">
										Save
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-if="itemRowData.selectType == 'ITEM'">
							<tr>
								<th>Item Type</th>
								<td>
									{{ itemRowData.fieldTypeName.toUpperCase() }} {{ itemRowData.selectType }}
								</td>
							</tr>
							<tr>
								<th>Item Label</th>
								<td>
									{{ itemRowData.itemLabeleName }}
								</td>
							</tr>
							<tr>
								<th>Item Value</th>
								<td>
									{{ itemRowData.itemValueName }}
								</td>
							</tr>
							<tr>
								<th>Data Type</th>
								<td >
									{{ itemRowData.dataTypeName }}
								</td>
							</tr>
							<tr>
								<th>Register</th>
								<td>
									{{ itemRowData.firstRegistUserId }}
								</td>
							</tr>
							<tr>
								<th>Register Date</th>
								<td>
									{{ itemRowData.firstRegistDate }}
								</td>
							</tr>
							<tr>
								<th>Converting Data</th>
								<td>
									<label
										v-for="(option, checkboxIndex) in convertColumnItemList"
										:key="checkboxIndex"
										class="checkbox-dic"
									>
										<input
											type="checkbox"
											:value="option.dataValue + ';' + option.dataColumnNo"
											v-model="selectValue"
											:disabled="!workAccessCheck('EMR MAPPING')"
										/><span class="checkbox"></span>
										<p><b style="color:#666;">{{ option.dataValue}}</b></p>
									</label>
									<p v-if="convertColumnItemList.length == 0" style="color: #ccc;">
										Please select the question converting data
									</p>
								</td>
							</tr>
							<tr style="border-bottom: none;">
								<td colspan="2">
									<button
										v-if="convertColumnItemList.length != 0 && workAccessCheck('EMR MAPPING')"
										type="button"
										class="btn-save fright"
										title="Save"
										@click.stop="doValidation('Item')"
									>
										Save
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "DataDictionaryList",
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "DICTIONARY",
			privilegeName: "ACCESS"
		}
		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.selectFieldList();
		this.getAccessList('DICTIONARY');
	},
	data() {
		return {
			item: {},
			accessList: [],
			fieldList: [],

			groupCloseList : [],
			fieldCloseList: [],
			componentCloseList: [],
			
			groupRowData: {},
			fieldRowData: {},
			itemRowData: {},

			isRightVal: "",
			isClicked: {},
			isTyping: false,

			convertColumnList : [],
			convertColumnComponentList:[],
			convertColumnItemList:[],

			selectedPageNo: [],
			selectComponentValue :[],
			selectValue: []
		};
	},
	methods: {
		selectFieldList() {
			this.logger.debug(this, "selectFieldList()");
			this.groupCloseList = [];
			this.fieldCloseList = [];
			this.componentCloseList = [];

			this.groupRowData = {};
			this.fieldRowData = {};
			this.componentRowData = {};
			this.itemRowData = {};

			this.isClicked = {};
			axios
				.post(this.config.contextRoot + "/dictionary/allDictionaryList.do", this.item)
				.then((response) => {
					console.log(response);
					this.fieldList = response.data;
					this.$nextTick(function () {
						EventBus.$emit("dictionary-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		keyupHandler(event) {
			this.logger.debug(this, "keyupHandler(event)");
			this.item.searchText = event.target.value;
			this.selectFieldList();
		},
		getLabelList(qFieldItem) {
			let returnLabelList = [];
			switch (qFieldItem.fieldTypeCd)
			{
				case '03':
					returnLabelList = qFieldItem.radioOptionsLabel.split(';');
					break;
				case '04':
					returnLabelList = qFieldItem.checkboxOptionsLabel.split(';');
					break;
				case '05':
					returnLabelList = qFieldItem.selectOptionsLabel.split(';');
					break;
				case '12':
					returnLabelList = qFieldItem.defaultValue.split(';');
					break;
				default:
					alert("error");
					break;
			}
			return returnLabelList;
		},
		foldGroup(questionGroupNo) {
			this.logger.debug(this, "foldGroup(questionGroupNo)");
			let index = this.groupCloseList.indexOf(questionGroupNo);
			if(index >= 0)
			{
				this.groupCloseList.splice(index, 1);
			}
			else
			{
				this.groupCloseList.push(questionGroupNo);
			}
		},
		foldField(questionNo) {
			this.logger.debug(this, "foldField(questionNo)");
			let index = this.fieldCloseList.indexOf(questionNo);
			if(index >= 0)
			{
				this.fieldCloseList.splice(index, 1);
			}
			else
			{
				this.fieldCloseList.push(questionNo);
			}
		},
		infoData(selectNo, type, itemData, rightNav) {
			this.logger.debug(this, "infoField(questionGroupNo)");
			
			this.isClicked = {};
			this.isClicked.id = selectNo;
			this.isClicked.type = type;
			this.isClicked.tag = itemData;

			this.isRightVal = rightNav;

			this.selectValue = [];
			this.selectedPageNo = [];
			axios
				.post(this.config.contextRoot + "/dictionary/select.do", {selectNo: selectNo, type: type, itemData: itemData})
				.then((response) => {
					console.log(response);

					this.groupRowData = {};
					this.fieldRowData = {};
					this.itemRowData = {};
					
					if(type == 'group')
					{
						this.groupRowData = response.data.dictionaryVo; 
						this.$nextTick(function () {
							EventBus.$emit("dictionary-loading", false);
						});
					}
					else if(type == 'field')
					{
						this.fieldRowData = response.data.dictionaryVo;
						this.convertColumn(this.fieldRowData.selectNo);
					}
					else
					{
						this.itemRowData = response.data.dictionaryVo;   
						this.convertColumnItem(); 
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		convertColumn(questionNo) {
			this.logger.debug(this, "convertColumn()");
			this.item.questionNo = questionNo;
			axios
				.post(this.config.contextRoot + "/dictionary/convertColumnList.do", this.item)
				.then((response) => {
					this.convertColumnList = response.data.convertColumnList;
					
					let convertProcessList = response.data.convertProcessList;
					for(let index = 0 ; index < convertProcessList.length ; index++)
					{
						this.selectedPageNo.push(convertProcessList[index].dataColumnNo);
					}
					
					this.$nextTick(function () {
						EventBus.$emit("dictionary-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		convertColumnItem(){
			this.logger.debug(this, "convertColumnItem()");
			this.convertColumnItemList = [];

			let convertItemData = {
				questionNo: this.itemRowData.questionNo,
				processValue: this.itemRowData.itemValueName,
				fieldNo: this.itemRowData.selectNo
			};
			axios
				.post(this.config.contextRoot + "/dictionary/convertColumnItemList.do", convertItemData)
				.then((response) => {
					this.convertColumnItemList = response.data.convertColumnItemList.emrDataList;

					let convertColumnProcessList = response.data.convertColumnItemList.emrProcessItemList;
					for(let index = 0 ; index < convertColumnProcessList.length ; index++)
					{
						this.selectValue.push(convertColumnProcessList[index].dataValue + ';' + convertColumnProcessList[index].dataColumnNo);
					}

					this.$nextTick(function () {
						EventBus.$emit("dictionary-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doValidation(type) {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						this.$confirm("Do you want to register the converting data?","Question","question").then(() => {
							EventBus.$emit("dictionary-loading", true);
							this.doInsert(type);
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert(type) {
			this.logger.debug(this, "doInsert()");
			let convertItem = {};
			if(type == 'Field')
			{
				convertItem.selectConvertList = this.selectedPageNo;
				convertItem.questionNo = this.fieldRowData.selectNo;
				convertItem.fieldValue = this.fieldRowData.questionName;
				convertItem.fieldType = type;
			}
			else if(type == 'Component')
			{
				convertItem.selectConvertList = this.selectComponentValue;
				convertItem.questionNo = this.componentRowData.questionNo;
				convertItem.fieldNo = this.componentRowData.selectNo;
				convertItem.fieldValue = this.componentRowData.fieldChildName;
				convertItem.fieldType = type;
			}
			else if(type == 'Item')
			{
				convertItem.selectConvertList = this.selectValue;
				convertItem.questionNo = this.itemRowData.questionNo;
				convertItem.fieldNo = this.itemRowData.selectNo;
				convertItem.fieldValue = this.itemRowData.itemValueName;
				convertItem.fieldType = type;
			}
			axios
				.post(this.config.contextRoot + "/dictionary/convertFieldInsert.do", convertItem)
				.then((response) => {
					if (response.status == 200)
					{
						this.$nextTick(function () {
							EventBus.$emit("dictionary-loading", false);
						});
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
	}
};
</script>
<style>
.scroll-dictionary{
	height: 100%;
	overflow-y: scroll;
}
</style>