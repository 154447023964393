<template>
	<div ref="queryClose" class="dialog-container">
		<div class="dialog" style="max-height: 560px; transform: translate(0, -280px); max-width: 1500px;">
			<form @submit.stop="doValidation()">
				<div class="top" style="height: 30px">
					<p class="txt-crud-title">
						Query Close
					</p>
				</div>
				<div class="center">
					<table class="tb-list-vertical">
                        <colgroup>
                            <col width="3%" />
                            <col width="3%" />
                            <col width="15%" />
                            <col width="auto" />
                            <col width="15%" />
                            <col width="7%" />
                            <!-- <col width="15%" /> -->
                            <col width="10%" />
                            <col width="15%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <input type="checkbox" name="checkAll" @click="checkAll($event)" />
                                </th>
                                <th>
                                    No
                                </th>
                                <th>
                                    Screening No
                                </th>
                                <!-- <th>
                                    Cell Name
                                </th> -->
                                <th>
                                    Field Name
                                </th>
                                <th>
                                    Data Order
                                </th>
                                <th>
                                    Answer Massage
                                </th>
                                <th>
                                    Status
                                </th>
                                <!-- <th>
                                    Message
                                </th> -->
                                <th>
                                    Register
                                </th>
                                <th>
                                    Date/Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(queryResult, index) in queryResolution.queryResultVoList" :key="index">
                                <td>
                                    <input
                                        type="checkbox"
                                        name="queryResultNo"
                                        v-model="checkedList"
                                        :value="queryResult.queryResultNo"
                                        :checked="checkedList.indexOf(queryResult.queryResultNo) >= 0 ? true : false"
                                    />
                                    <!-- {{ queryResult.testQueryResultNo }} -->
                                </td>
                                <td>
                                    {{ index+1 }}
                                </td>
                                <td>
                                    {{ queryResult.screeningName }}
                                </td>
                                <!-- <td>
                                    {{ queryResult.cellName }}
                                </td> -->
                                <td>
                                    {{queryResult.visitName}} > {{queryResult.pageName}} > {{queryResult.procLabel}} > {{queryResult.questionName}} ({{queryResult.procFieldName}})
                                </td>
                                <td>
                                    {{ queryResult.repeatSortOrder }}
                                </td>
                                <td>
                                    {{ queryResult.answerMsg == "" ? "-" : queryResult.answerMsg}}
                                </td>
                                <td
                                class="bold"
                                    :class="getStatusName(queryResult.queryStatusCd)=='CLOSE' ? 'red' : getStatusName(queryResult.queryStatusCd)=='OPEN' ? 'blue' : getStatusName(queryResult.queryStatusCd)=='ANSWER' ? 'green' : ''"
                                >
                                    {{ getStatusName(queryResult.queryStatusCd)}}
                                </td>
                                <!-- <td>
                                    <textarea name="sendMsg" type="text" class="textarea-crud" v-model="queryResult.sendMsg" maxlength="500" style="height: 50px; padding: 5px;"></textarea>
                                </td> -->
                                <td>
                                    <b>{{ queryResult.firstRegistUserId }}</b><br>
                                    ({{ queryResult.userName }})
                                </td>
                                <td>
                                    {{ queryResult.firstRegistDate.substring(0, 19) }}
                                </td>
                            </tr>
                            <tr v-if="queryResolution.queryResultVoList.length == 0">
                                <td colspan="8" class="no-list">
                                    There is no registered query, Please register the query.
                                </td>
                            </tr>
                        </tbody>
                    </table>
				</div>
				<div class="bottom">
                    <button type="button" class="btn-save fright" title="Save" @click="doValidation()">
                        Save
                    </button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doClose()">
						Close
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "queryClose",
	created() {
        this.logger.debug(this, "created()");
        this.item.projectNo = this.$route.params.projectNo;
        this.queryResolution = this.selectQueryResolution;
		this.firstRegistUserId = this.userInfo.userId;

        this.queryResolution.queryResultVoList.sort(function (a, b) {
			const valueOfKeyA = a['cellName'].toUpperCase();
			const valueOfKeyB = b['cellName'].toUpperCase();
			return valueOfKeyA.localeCompare(valueOfKeyB, undefined, {
			numeric: true,
			sensitivity: 'base',
			});
		});
        this.selectComCodeList();
    },
    props: {
		selectQueryResolution: {
			type: Object,
			required: true
		},
		queryStatusList: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
            item: {},
            queryResolution: {},
			testQueryResultNo: "",
            codeList: [],
            checkedList: [],
		};
    },
	methods: {
        selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["QUERY_STATUS_CD"]})
				.then((response) => {
					this.codeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
        getStatusName(code){
            let statusName = '';
            for(let i = 0; i < this.codeList.length; i++){
                if(this.codeList[i].comCode == code){
                    statusName = this.codeList[i].comCodeName;
                }
            }

            return statusName;
        },
        doValidation() {
			this.logger.debug(this, "doValidation()");
            if(this.checkedList.length < 1){
                this.$alert("Checked Query!", "Warning", "warning");
                return false;
            }
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
                        this.doInsert();
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
        doInsert() {
            this.$set(this.queryResolution, "checkedList", this.checkedList);
			axios
				.post(this.config.contextRoot + "/queryResolution/queryClose.do", this.queryResolution)
				.then((response) => {
					if (response.status == 200) {
                        this.$alert("Query has been saved successfully.", "Info", "info").then(() => {
							this.doClose();
							location.reload();
						});
					} else {
						alert(response.status);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
        checkAll(event) {
			this.logger.debug(this, "checkAll(event)");
			if (event.target.checked) {
				this.checkedList = this.queryResolution.queryResultVoList.map((item) => {
					return item.queryResultNo;
				});
			} else {
				this.checkedList = [];
			}
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.queryClose.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.queryClose.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("view-close", "isQueryClose");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
.red {
	color: rgb(224, 91, 91);
}
.green {
	color: #9ecc5a;
}

.blue{
	color: #2bade0;
}
</style>import { set } from "core-js/core/dict";import { set } from "core-js/core/dict";

